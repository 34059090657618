import { useEffect } from 'react';
import { getKioskData } from '@intergamma/kiosk-util';

import './App.css';
import Prerequisites from './Prerequisites';
import { redirect } from './redirect';

function App() {
	useEffect(() => {
		redirectToHomePage();
	}, []);

	return (
		<div className="App">
			<header className="App-header">
				<p>Loading Kiosk...</p>
				<Prerequisites />
			</header>
		</div>
	);
}

export async function redirectToHomePage() {
	const kioskData = await getKioskData();

	if (!kioskData.isKiosk) {
		if (process.env.REACT_APP_REDIRECT_URL) {
			redirect(process.env.REACT_APP_REDIRECT_URL);
		}
	} else {
		redirect(kioskData.startUrl);
	}
}

export default App;
